import { FunctionComponent, useEffect, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../dto/api';
import { sessionStorageGetItem, sessionStorageSetItem } from '../../utils/sessionStorageMethods';
import { SESSION_STORAGE_KEYS } from '../../enums/sessionStorageKeys';
import { stringToUserFriendlyURL } from '../../utils/stringFormatMethods';
import ServiceDetailTemplate from '../../containers/templates/ServiceDetailTemplate/ServiceDetailTemplate';
import { getRoleIdByUserRole } from '../../utils/role';
import callToast from '../../components/blocks/ToastMessage/_parts/callToast/callToast';
import RoutesEnum from '../../enums/routes';
import { normalizeRelatedServices } from '../../utils/normalizeData';

interface LearnDetailViewProps extends IWithUserRoleProps {}

const LearnDetailView: FunctionComponent<LearnDetailViewProps> = ({ user_role: userRole }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState<Record<string, any>>();

  const mentorId = location.state?.mentorId;
  const viewAllLink = `/${userRole}/${RoutesEnum.LEARN}`;

  const getLearnMentorsDetails = async () => {
    try {
      const response = await api.Content.getLearnMentorsDetails(mentorId ? mentorId : '');
      const responseData = response.data;

      if (responseData.success && responseData.data) {
        setData({
          ...responseData.data,
          relatedMentors: normalizeRelatedServices(responseData.data.relatedMentors, 'learn', userRole), // Normalize data here
        });
      } else {
        console.error('Error:', responseData.errors);

        callToast({
          title: 'Something went wrong',
          children: `We couldn't retrieve the data. Please try opening the corresponding page again from the services list.`,
          variation: 'error',
        });

        navigate(`/${userRole}/${RoutesEnum.LEARN}`);
      }
    } catch (error) {
      console.error('Error: ', error);

      callToast({
        title: 'Something went wrong',
        children: `We couldn't retrieve the data. Please try opening the corresponding page again from the services list.`,
        variation: 'error',
      });

      navigate(`/${userRole}/${RoutesEnum.LEARN}`);
    }
  };

  useEffect(() => {
    setData(undefined);
    getLearnMentorsDetails();
  }, [location]);

  return (
    <ServiceDetailTemplate
      userRole={userRole}
      title={data?.mentorName}
      description={data?.mentorDescription}
      relatedServices={data?.relatedMentors}
      viewAll={viewAllLink}
      loading={data ? false : true}
    />
  );
};

export default with_user_role(LearnDetailView);
