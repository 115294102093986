import { FunctionComponent, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as AboutIll } from '../../asset/images/about_ill.svg';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import MainButton from '../../components/buttons/MainButton/MainButton';

import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket_font.svg';
import { ReactComponent as AstronomerIcon } from '../../asset/images/icons/astronomer_font.svg';
import { ReactComponent as PlanetIcon } from '../../asset/images/icons/planet_font.svg';
import { ReactComponent as Ill } from '../../asset/images/manifesto/slide_2_ill.svg';

import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import Grid from '../../components/blocks/Grid/Grid';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import ValuesSection from '../../components/sections/ValuesSection/ValuesSection';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import { GENERAL_CONTENT } from '../../data/generalContent';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import WhatIsAstronomicPopUp from '../../components/popups/WhatIsAstronomicPopUp/WhatIsAstronomicPopUp';
import NavigationLink from '../../components/links/NavigationLink/NavigationLink';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface AboutViewProps extends IWithUserRoleProps {}

const AboutView: FunctionComponent<AboutViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  const [isPopUpActive, setIsPopUpActive] = useState(false);

  const togglePopUp = () => {
    setIsPopUpActive(!isPopUpActive);
  };

  return (
    <MainTemplateForMainPages footerSectionVariation="var-1">
      <MetaTags
        title="About us"
        description="Building companies is hard. Entrepreneurship can feel like an isolated journey. Finding the right team, mentors, investors, and customers can feel like searching for distant stars in an infinite sky."
      />

      <HeroSection
        secondaryTitle={<mark>About us</mark>}
        title="No one goes to the moon alone"
        text={
          <>
            <p>
              <mark>Building companies is hard.</mark> Entrepreneurship can feel like an isolated journey. Finding the
              right team, mentors, investors, and customers can feel like searching for distant stars in an infinite
              sky.
            </p>

            <p>
              Astronomic solves these challenges by being your "cofounder as a service." We're your one-stop shop for
              everything you need in order to launch, build, grow, scale, and exit your company. Point to the stars and
              tell us where we're going, and we'll provide the direction and momentum to get us there.
            </p>
          </>
        }
        sectionVariation="var-1-c-3"
        illustration={<AboutIll style={{ marginRight: 'auto', marginLeft: 'auto', maxHeight: '512px' }} />}
        buttonGroup={[
          !isUserLoggedIn ? (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
            </MainButton>
          ) : (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              {GENERAL_CONTENT.SCHEDULE_A_MEETING}
            </MainButton>
          ),
          <MainButton visualType="blank" sizeType="large" onClick={togglePopUp}>
            How Astronomic works
          </MainButton>,
        ]}
        markTagColor="blue"
      />

      <WhatIsAstronomicPopUp
        whatIsPopUpActive={isPopUpActive}
        changeWhatIsPopUpActiveState={togglePopUp}
        userRole={user_role}
      />

      <SectionDivider color="white" />

      <ChildrenWithHeaderSection backgroundColor="white">
        <Grid
          itemsInRow={3}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Our Vision"
              text="To innovate our way into the cosmos"
              icon={<RocketIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
            <TitleWithIconAndTextCard
              title="Our Mission"
              text="To enable as much entrepreneurship as possible"
              icon={<AstronomerIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
            <TitleWithIconAndTextCard
              title="Our Purpose"
              text="To connect the world, using entrepreneurship as a conduit for social change"
              icon={<PlanetIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title="Diversity, Equity, Inclusion, and Belonging (DEIB)"
            titleSize="h3"
            text={
              <>
                At Astronomic, we’re building an open, collaborative, and inclusive culture that values each person’s
                unique contributions in the collective foundation of our company, community, and customers.
              </>
            }
          />
        }
      ></ChildrenWithHeaderSection>

      <SectionDivider color="blue" />

      <HeroSection
        title={<>Built with 🚀 in Austin, TX</>}
        titleSize="h2"
        text={
          <>
            Astronomic was founded in 2020 by{' '}
            <NavigationLink path="https://www.linkedin.com/in/chrisbeaman/" target="_blank" rel="nofollow">
              Chris Beaman
            </NavigationLink>
            , who previously founded and exited two SaaS companies. After participating in the Techstars accelerator
            program, Chris was eager to build a tech-enabled “forever accelerator.” With a background in software and a
            passion for community, Chris set out to build Astronomic and enable anyone with a fire in their belly to
            start their own company.
          </>
        }
        sectionVariation="var-1-c-2"
        wrapperClass={null}
        illustration={<Ill style={{ maxWidth: '520px' }} />}
        illustrationPosition="var-a"
      />

      {/* <SectionDivider color="gray" />

      <ValuesSection
        sectionVariation="var-1"
        itemsInRow={5}
        title="Our Values"
        text="In our orbit, entrepreneurs are “astronomers” who uphold the following values."
        itemsData={[
          {
            title: 'Humility',
            text: 'We’re open to critical feedback',
          },
          {
            title: 'Courage',
            text: 'We have the strength to persevere',
          },
          {
            title: 'Integrity',
            text: 'We’re open, honest, and forthright',
          },
          {
            title: 'Ambition',
            text: 'We dream big and work hard',
          },
          {
            title: 'Diversity',
            text: 'We embrace all people and ideas',
          },
        ]}
      /> */}

      {/* <SectionDivider color="gray" /> */}

      {/* <TestimonialsSection backgroundColor="gray" /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(AboutView);
