import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../../../asset/images/icons/arrow_in_circle.svg';
import styles from './PricingCalculatorWidget.module.scss';
import classNames from 'classnames';
import RangeSlider from '../../inputs/RangeSlider/RangeSlider';
import RadioSwitch from '../../inputs/RadioSwitch/RadioSwitch';

const MIN_SERVICE_COST = 100; //this may be lower than real number. It helps to provide more accurate total discount if lower but takes much more power to calculate

type PayingVariants = 'cash' | 'prepaid';

enum RF_BUNDLE {
  FIRST = 500,
  SECOND = 1000,
  THIRD = 5000,
  FOURTH = 10000,
}

enum EQUITY_VARIANTS {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
}

const PAYING_IN_OPTIONS = [
  { value: 'cash' as PayingVariants, label: 'Cash' },
  { value: 'prepaid' as PayingVariants, label: 'Prepaid Rocket fuel' },
];

const PREPAID_RF_BONUS_AMOUNT = {
  [RF_BUNDLE.FIRST]: 0.07,
  [RF_BUNDLE.SECOND]: 0.08,
  [RF_BUNDLE.THIRD]: 0.09,
  [RF_BUNDLE.FOURTH]: 0.1,
};

const EQUITY_OPTIONS = [
  { value: EQUITY_VARIANTS.ZERO, label: 'No equity' },
  { value: EQUITY_VARIANTS.ONE, label: '1% equity' },
  { value: EQUITY_VARIANTS.TWO, label: '2% equity' },
  { value: EQUITY_VARIANTS.THREE, label: '3% equity' },
];

const EQUITY_DISCOUNTS = {
  [EQUITY_VARIANTS.ZERO]: 0.05,
  [EQUITY_VARIANTS.ONE]: 0.1,
  [EQUITY_VARIANTS.TWO]: 0.15,
  [EQUITY_VARIANTS.THREE]: 0.25,
};

interface PricingCalculatorWidgetProps {
  className?: string;
  layoutVar?: 'default' | 'var-a';
  variant?: '1' | '2';
}

const PricingCalculatorWidget: FunctionComponent<PricingCalculatorWidgetProps> = ({
  layoutVar = 'default',
  className,
  variant = '1',
}) => {
  const [value, setValue] = useState(RF_BUNDLE.FIRST);

  const [payingInSelectedOption, setPayingInSelectedOption] = useState(PAYING_IN_OPTIONS[0].value);
  const [equitySelectedOption, setEquitySelectedOption] = useState(EQUITY_OPTIONS[0].value);
  const [totalBonus, setTotalBonus] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(EQUITY_DISCOUNTS[0]);

  const handlePayingInChange = (value: string) => {
    setPayingInSelectedOption(value as PayingVariants);
  };

  const handleEquityChange = (value: string) => {
    setEquitySelectedOption(value as EQUITY_VARIANTS);
  };

  // Function to determine step dynamically
  const getStep = (currentValue) => {
    return getHighestValueOfRfBundle(currentValue);
  };

  const getHighestValueOfRfBundle = (currentValue) => {
    let highestValue = RF_BUNDLE.FIRST;

    for (const key of Object.keys(RF_BUNDLE)) {
      const value = RF_BUNDLE[key as keyof typeof RF_BUNDLE];

      if (currentValue >= value) {
        highestValue = value;
      }
    }

    return highestValue;
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setValue(newValue > MIN_SERVICE_COST ? newValue - MIN_SERVICE_COST : newValue);
    // setValue(newValue);
  };

  const getTotalBonus = (numberForIteration?, moneySpend?, _maxFractionDigits = 1) => {
    const serviceCost = numberForIteration || MIN_SERVICE_COST;
    const bonusPerPurchase = EQUITY_DISCOUNTS[equitySelectedOption];
    const bundleMoney = moneySpend || value;
    let bundleBonus = 0;

    // console.log('MONEY SPEND: ', bundleMoney);

    if (payingInSelectedOption === 'prepaid') {
      bundleBonus = PREPAID_RF_BONUS_AMOUNT[getHighestValueOfRfBundle(value)];
    }

    let currentBudget = bundleMoney + bundleMoney * bundleBonus;
    let currentBonus = bundleMoney * bundleBonus;

    while (currentBudget >= serviceCost) {
      currentBudget = currentBudget - serviceCost + serviceCost * bonusPerPurchase;

      currentBonus = currentBonus + serviceCost * bonusPerPurchase;

      // console.log(
      //   `Current budget: ${currentBudget}, current BONUS: ${currentBonus}, current discount ${
      //     (100 / bundleMoney) * currentBonus
      //   }`
      // );
    }

    // console.log(`Total discount: ${(100 / bundleMoney) * currentBonus}`);

    // setTotalBonus(currentBonus);
    // setTotalDiscount((100 / bundleMoney) * currentBonus);
    // console.log('CURRENT BONUS: ', currentBonus);

    return {
      currentBonus,
      currentBonusFormatted: currentBonus.toLocaleString('en-US', { maximumFractionDigits: _maxFractionDigits }),
    };
  };

  useEffect(() => {
    const totalBonus = getTotalBonus().currentBonus;
    setTotalBonus(totalBonus);
    setTotalDiscount((100 / value) * totalBonus);

    getPayingInMessage();
  }, [value, payingInSelectedOption, equitySelectedOption]);

  const getPayingInMessage = () => {
    let message = 'Get more credits by paying with Prepaid Rocket Fuel' as string | React.ReactElement;

    if (payingInSelectedOption === 'prepaid') {
      const bundlePercentageBonus = Math.floor(PREPAID_RF_BONUS_AMOUNT[getHighestValueOfRfBundle(value)] * 100);

      message = (
        <>
          Get <b>{bundlePercentageBonus}%</b> more credits by paying with Prepaid Rocket Fuel
        </>
      );
    }

    return message;
  };

  const getBonusAmountBasedOnEquity = (number, _maxFractionDigits = 1) => {
    return (number * EQUITY_DISCOUNTS[equitySelectedOption]).toLocaleString('en-US', {
      maximumFractionDigits: _maxFractionDigits,
    });
  };

  const getBonusAmountBasedOnTotalBonus = (number) => {
    const currentMaxBonus = Math.floor(totalDiscount);

    console.log('THIS: ', currentMaxBonus);
    return (number * currentMaxBonus) / 100;
  };

  let tableContent;

  if (variant === '1') {
    tableContent = {
      rows: [
        {
          type: 'header',
          cols: ['Connect', 'Price', 'Equity bonus', 'Total bonus*'],
        },
        {
          type: null,
          cols: ['Founder Dinners', 'Free', '-', '-'],
        },
        {
          type: null,
          cols: [
            'Fundraising Dinners',
            <>
              <b>$10,000</b>/din
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(10000)}</b>
            </>,
            <>
              <b>${getTotalBonus(100, 10000).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Biz Dev Dinners',
            <>
              <b>$10,000</b>/din*
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(10000)}</b>
            </>,
            <>
              <b>${getTotalBonus(100, 10000).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Concierge Coffees',
            <>
              <b>$500</b>/mo
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(500)}</b>
            </>,
            <>
              <b>${getTotalBonus(10, 500).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Investor Club',
            <>
              <b>$250</b>/mo*
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(250)}</b>
            </>,
            <>
              <b>${getTotalBonus(10, 250).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Build'],
        },
        {
          type: null,
          cols: [
            'Services',
            <>
              <b>$75</b>/hr
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(75)}</b>
            </>,
            <>
              <b>${getTotalBonus(5, 75).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Learn'],
        },
        {
          type: null,
          cols: [
            'Mentorship',
            <>
              <b>$250</b>/hr
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(250)}</b>
            </>,
            <>
              <b>${getTotalBonus(10, 250).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Fund'],
        },
        {
          type: null,
          cols: ['Venture Capital', 'Free to apply', '-', '-'],
        },
        {
          type: null,
          cols: ['Venture Studio', 'Free to apply', '-', '-'],
        },
        {
          type: null,
          cols: ['Venture Debt', 'Free to apply', '-', '-'],
        },
        {
          type: null,
          cols: [
            'Investor Emails',
            <>
              <b>$500</b>/hr
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(500)}</b>
            </>,
            <>
              <b>${getTotalBonus(10, 500).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Fundraising Dinners',
            <>
              <b>$10,000</b>/din
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(10000)}</b>
            </>,
            <>
              <b>${getTotalBonus(100, 10000).currentBonusFormatted}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Grow'],
        },
        {
          type: null,
          cols: [
            'Data',
            <>
              <b>$0.5</b>/row
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(0.5, 5)}</b>
            </>,
            <>
              <b>${getTotalBonus(0.01, 0.5, 5).currentBonusFormatted}</b>
            </>,
          ],
        },
      ],
    };
  } else {
    tableContent = {
      rows: [
        {
          type: 'header',
          cols: ['Connect', 'Price', 'Bonus'],
        },
        {
          type: null,
          cols: ['Founder Dinners', 'Free', '-'],
        },
        {
          type: null,
          cols: [
            'Fundraising Dinners',
            <>
              <b>$10,000</b>/din
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(10000)}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Biz Dev Dinners',
            <>
              <b>$10,000</b>/din*
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(10000)}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Concierge Coffees',
            <>
              <b>$500</b>/mo
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(500)}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Investor Club',
            <>
              <b>$250</b>/mo*
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(250)}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Build'],
        },
        {
          type: null,
          cols: [
            'Services',
            <>
              <b>$75</b>/hr
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(75)}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Learn'],
        },
        {
          type: null,
          cols: [
            'Mentorship',
            <>
              <b>$250</b>/hr
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(250)}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Fund'],
        },
        {
          type: null,
          cols: ['Venture Capital', 'Free to apply', '-'],
        },
        {
          type: null,
          cols: ['Venture Studio', 'Free to apply', '-'],
        },
        {
          type: null,
          cols: ['Venture Debt', 'Free to apply', '-'],
        },
        {
          type: null,
          cols: [
            'Investor Emails',
            <>
              <b>$500</b>/hr
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(500)}</b>
            </>,
          ],
        },
        {
          type: null,
          cols: [
            'Fundraising Dinners',
            <>
              <b>$10,000</b>/din
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(10000)}</b>
            </>,
          ],
        },
        {
          type: 'header',
          cols: ['Grow'],
        },
        {
          type: null,
          cols: [
            'Data',
            <>
              <b>$0.5</b>/row
            </>,
            <>
              <b>${getBonusAmountBasedOnEquity(0.5, 5)}</b>
            </>,
          ],
        },
      ],
    };
  }

  return (
    <div className={classNames(styles['widget'], styles[`widget--layout-${layoutVar}`], className)}>
      <div className={styles['parts']}>
        <div className={styles['parts__item']}>
          <div className={classNames(styles['tile'], 'tile tile--padding-0px tile--border-radius-8px')}>
            <div className={styles['table']}>
              {tableContent.rows.map((row, rowIndex) => {
                return (
                  <div
                    className={classNames(styles['row'], row.type === 'header' && styles['row--header'])}
                    key={rowIndex}
                  >
                    {row.cols.map((col, colIndex) => {
                      return (
                        <div className={styles['col']} key={colIndex}>
                          <p className="text text--body-3">{col}</p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles['parts__item']}>
          <div className={classNames(styles['tile'], 'tile tile--padding-0px tile--border-radius-8px')}>
            <div className={styles['form']}>
              <div className={styles['form__body']}>
                <div className={styles['steps']}>
                  <div className={styles['steps__item']}>
                    <div className={styles['steps__item__part-left']}>
                      <div className={styles['steps__item__dot']}></div>
                      <div className={styles['steps__item__line']}></div>
                    </div>

                    <div className={styles['steps__item__part-right']}>
                      <div className={styles['spend-amount-container']}>
                        <p className={classNames(styles['form__text-1'], 'text text--body-3')}>If I spend</p>
                        <p className={classNames(styles['form__text-2'], 'text text--h3 text--h3--font-weight-400')}>
                          ${value.toLocaleString('en-US')}
                        </p>
                      </div>

                      <div className={styles['form__input']}>
                        <RangeSlider
                          min={MIN_SERVICE_COST}
                          max={50000 + MIN_SERVICE_COST}
                          step={getStep(value)}
                          value={value}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                          visualStepsCount={6}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles['steps__item']}>
                    <div className={styles['steps__item__part-left']}>
                      <div className={styles['steps__item__dot']}></div>
                      <div className={styles['steps__item__line']}></div>
                    </div>

                    <div className={styles['steps__item__part-right']}>
                      <p className={classNames(styles['form__text-1'], 'text text--body-3')}>paying in</p>

                      <div className={styles['form__input']}>
                        <RadioSwitch
                          name={`${variant}-paying_in`}
                          options={PAYING_IN_OPTIONS}
                          selected={payingInSelectedOption}
                          onChange={handlePayingInChange}
                        />
                      </div>

                      <p className={classNames(styles['form__text-6'], 'text text--caption')}>{getPayingInMessage()}</p>
                    </div>
                  </div>

                  <div className={styles['steps__item']}>
                    <div className={styles['steps__item__part-left']}>
                      <div className={styles['steps__item__dot']}></div>
                    </div>

                    <div className={styles['steps__item__part-right']}>
                      <p className={classNames(styles['form__text-1'], 'text text--body-3')}>and I grant</p>

                      <div className={styles['form__input']}>
                        <RadioSwitch
                          name={`${variant}-equity`}
                          options={EQUITY_OPTIONS}
                          selected={equitySelectedOption}
                          onChange={handleEquityChange}
                        />
                      </div>

                      <p className={classNames(styles['form__text-6'], 'text text--caption')}>
                        Get <b>${EQUITY_DISCOUNTS[equitySelectedOption] * 100}</b> in credit for every $100 spent.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles['form__footer']}>
                <div className={styles['form__footer__icon-container']}>
                  <Arrow />
                </div>

                {variant === '1' && (
                  <div className={styles['form__footer__parts']}>
                    <div className={styles['form__footer__parts__item']}>
                      <p className={classNames(styles['form__text-3'], 'text text--body-3 text--bold-2')}>I'll earn</p>
                      <p className={classNames(styles['form__text-4'], 'text text--h4')}>
                        $
                        {totalBonus % 1 !== 0
                          ? totalBonus.toLocaleString('en-US', { maximumFractionDigits: 1 })
                          : totalBonus.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                      </p>
                      <p className={classNames(styles['form__text-5'], 'text text--body-3')}>in credit</p>
                    </div>

                    <div className={styles['form__footer__parts__item']}>
                      <p className={classNames(styles['form__text-3'], 'text text--body-3 text--bold-2')}>
                        Equivalent to
                      </p>
                      <p className={classNames(styles['form__text-4'], 'text text--h4')}>
                        ~{Math.floor(totalDiscount)}% bonus
                      </p>
                      <p className={classNames(styles['form__text-5'], 'text text--body-3')}>
                        on all products and services
                      </p>
                    </div>
                  </div>
                )}

                {variant === '2' && (
                  <p className={classNames(styles['form__footer__text'], 'text text-body-3')}>
                    then I'll earn{' '}
                    <span className={classNames(styles['form__footer__text__price'], 'text text--h3')}>
                      $
                      {totalBonus % 1 !== 0
                        ? totalBonus.toLocaleString('en-US', { maximumFractionDigits: 1 })
                        : totalBonus.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                    </span>{' '}
                    in Rocket Fuel credit.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const applyDiscount = (number, discount) => {
  return (number - number * (discount / 100)).toLocaleString('en-US', { maximumFractionDigits: 1 });
};

export default PricingCalculatorWidget;
