import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './AccountDashboard.module.scss';
import ServiceCard from '../../../../../components/blocks/ServiceCard/ServiceCard';
import HorizontalCard from '../../../../../components/blocks/HorizontalCard/HorizontalCard';
import { ReactComponent as ShareCardBG } from '../../../../../asset/images/share_card_bg.svg';
import { ReactComponent as ReferCardBG } from '../../../../../asset/images/refer_bg.svg';
import { ReactComponent as FbIcon } from '../../../../../asset/images/icons/fb.svg';
import { ReactComponent as LiIcon } from '../../../../../asset/images/icons/in.svg';
import { ReactComponent as TwIcon } from '../../../../../asset/images/icons/tw.svg';
import { ReactComponent as EmailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as ShareIcon } from '../../../../../asset/images/icons/share.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../../asset/images/icons/external_link.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import { createPortal } from 'react-dom';
import PopUp from '../../../../../components/popups/PopUp/PopUp';
import Input from '../../../../../components/inputs/Input/Input';
import ComingSoonMessage from '../../../../../components/texts/ComingSoonMessage/ComingSoonMessage';
import { sessionStorageGetItem, sessionStorageSetItem } from '../../../../../utils/sessionStorageMethods';
import { SESSION_STORAGE_KEYS } from '../../../../../enums/sessionStorageKeys';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../../../dto/api';
import api from '../../../../../api';
import PreloadProductCard from '../../../../../components/blocks/PreloadProductCard/PreloadProductCard';
import RoutesEnum from '../../../../../enums/routes';
import { stringToUserFriendlyURL, truncateString } from '../../../../../utils/stringFormatMethods';
import { UserRoleEnum } from '../../../../../enums/user';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { getRoleIdByUserRole } from '../../../../../utils/role';
import MetaTags from '../../../../../components/seo/MetaTags/MetaTags';
import ShareLink from '../../../../../components/links/ShareLink/ShareLink';
import ShowOnlyInSomeEnv from '../../../../../components/helpers/ShowOnlyInSomeEnv/ShowOnlyInSomeEnv';

interface AccountDashboardProps {}

const AccountDashboard: FunctionComponent<AccountDashboardProps> = () => {
  const [referPopUpActive, setSkipPopUpActive] = useState(false);
  const changeReferPopUpActiveState = (state) => {
    setSkipPopUpActive(state);
  };

  const [services, setServices] = useState<Array<any>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // let cacheData = JSON.parse(sessionStorageGetItem(SESSION_STORAGE_KEYS.CACHE_BUILD_SERVICES)!);

    const getServices = async () => {
      try {
        const response = await api.Content.getServicesByCategoryAndStage({
          roleId: getRoleIdByUserRole(Object.keys(UserRoleEnum)[0] as UserRoleEnum),
          disciplineId: null,
          stage: null,
        });
        const responseData = response.data;
        if (responseData.success === true && responseData.data) {
          setServices(responseData.data);
          // sessionStorageSetItem(SESSION_STORAGE_KEYS.CACHE_BUILD_SERVICES, JSON.stringify(responseData.data));
        } else {
          console.error('Error:', responseData.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;
        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }
        console.error('Error: ', error);
      } finally {
        setLoading(false);
      }
    };

    // if (cacheData) {
    //   setServices(cacheData);
    //   setLoading(false);
    // } else {
    //   getServices();
    // }

    getServices();
  }, []);

  const shareTitle = 'Astronomic - Your One Stop Startup Shop 🚀';
  const shareDescription =
    'Astronomic is your one stop startup shop. We help with each stage of growth, from strategy, to design, software, marketing, fundraising, hiring, data, and more.';
  const shareUrl = 'https://stage.astronomic.com/';

  return (
    <div className={styles['dashboard']}>
      {/* <div className={styles['where-you-left-off']}>
        <p className={classNames(styles['where-you-left-off__title'], 'text text--caps')}>
          Continue where you left off
        </p>

        <ServiceCard
          title="UI/UX design"
          text="Do web research into competitors and opportunities and/or call prospective customers."
          link="/"
          size="small"
          price={null}
        />
      </div> */}

      <MetaTags title="Account - Dashboard" />

      <div className={styles['share']}>
        <HorizontalCard
          className={styles['share__card']}
          textColor="var(--color-white)"
          backgroundColor="var(--color-space-blue-1)"
          backgroundIllustration={<ShareCardBG className={styles['share__bg-el']} />}
          footer={
            <div className={styles['share__footer']}>
              <div className={styles['share__footer__left']}>
                {/* <p className={classNames(styles['share__text-1'], 'text text--h3')}>$25 in credits</p> */}
                <div className={styles['share__footer__button-list']}>
                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={<FbIcon style={{ width: '13px', fillOpacity: '1' }} />}
                  >
                    Share
                    <ShareLink shareFor="Facebook" title={shareTitle} url={shareUrl} />
                  </MainButton>

                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={<LiIcon style={{ width: '13px', fillOpacity: '1' }} />}
                  >
                    Share
                    <ShareLink shareFor="LinkedIn" title={shareTitle} url={shareUrl} summary={shareDescription} />
                  </MainButton>

                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={<TwIcon style={{ width: '13px', fillOpacity: '1' }} />}
                  >
                    Share
                    <ShareLink shareFor="Twitter" title={shareTitle} url={shareUrl} />
                  </MainButton>

                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={
                      <EmailIcon
                        style={{
                          width: '15px',
                          fillOpacity: '1',
                          fill: 'var(--color-white)',
                        }}
                      />
                    }
                  >
                    Share
                    <ShareLink shareFor="Email" title={shareTitle} url={shareUrl} summary={shareDescription} />
                  </MainButton>

                  {/* <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={
                      <ShareIcon
                        style={{
                          width: '13px',
                          fillOpacity: '1',
                          fill: 'var(--color-white)',
                        }}
                      />
                    }
                  >
                    Share
                  </MainButton> */}
                </div>
              </div>
              <div className={styles['share__footer__right']}></div>
            </div>
          }
        >
          <p className={classNames(styles['share__title'], 'text text--caps')}>Share</p>

          <p className={classNames(styles['share__text'], 'text text--body-2')}>
            Share Astronomic with your friends and colleagues and let them discover its potential
          </p>
        </HorizontalCard>
      </div>

      <div className={styles['recommended']}>
        <p className={classNames(styles['recommended__title'], 'text text--caps')}>Suggested for you</p>

        <div className={styles['recommended__list']}>
          {services && services?.length > 0
            ? services
                ?.sort(() => 0.5 - Math.random())
                .slice(0, 4)
                .map((item, i) => {
                  return (
                    <ServiceCard
                      title={item.name}
                      text={truncateString(item.description, 200)}
                      price={null}
                      link={`/${Object.keys(UserRoleEnum)[0]}/${RoutesEnum.BUILD}/${stringToUserFriendlyURL(
                        item.name
                      )}`}
                      key={item.id}
                      navigateState={{ serviceId: item.id }}
                    />
                  );
                })
            : [1, 2, 3, 4].map((item, i) => <PreloadProductCard key={i} />)}
        </div>
      </div>

      <ShowOnlyInSomeEnv showInWhatEnv={['development', 'staging']}>
        <div className={styles['refer']} style={{ position: 'relative' }}>
          <HorizontalCard
            className={styles['refer__card']}
            textColor="var(--color-white)"
            backgroundColor="var(--color-main)"
            backgroundIllustration={<ReferCardBG className={styles['refer__bg-el']} />}
            footer={
              <div className={styles['refer__footer']}>
                <div className={styles['refer__footer__left']}>
                  <p className={classNames(styles['refer__text-1'], 'text text--h3')}>10% for one year</p>
                </div>
                <div className={styles['refer__footer__right']}>
                  <div className={styles['refer__footer__button-list']}>
                    {/* <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconRight={<ExternalLinkIcon />}
                    onClick={() => changeReferPopUpActiveState(true)}
                  >
                    Share
                  </MainButton> */}

                    {/* <NavigationLink
                    path="https://www.astronomic.com/"
                    target="_blank"
                    rel="nofollow"
                    colorType="white"
                    sizeType="large"
                  >
                    Share
                  </NavigationLink> */}
                  </div>
                </div>
              </div>
            }
          >
            <p className={classNames(styles['refer__title'], 'text text--caps')}>
              Refer a friend. Earn 10% of all of their sales for 1 year.
            </p>

            <p className={classNames(styles['refer__text'], 'text text--body-2')}>
              Know someone who'd benefit from Astronomic? Send them our website. When they sign up, have them put your
              email address in our referral field so that we can credit you. We'll pay you 10% of all of their sales in
              Rocket Fuel for 1 year.
            </p>
          </HorizontalCard>

          <ComingSoonMessage
            type="overlay"
            customMessage="API is in progress"
            customMessageTwo="(this entire part won't be shown for production env)"
          />
        </div>
      </ShowOnlyInSomeEnv>

      {/* <ComingSoonMessage type="overlay" /> */}

      {createPortal(
        <PopUp
          className={styles['refer-pop-up']}
          title="Refer a friend"
          active={referPopUpActive}
          onPopUpStateChange={changeReferPopUpActiveState}
          footer={
            <div className={styles['refer-pop-up__footer-button-list']}>
              <div className={styles['refer-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="white"
                  onClick={() => {
                    changeReferPopUpActiveState(false);
                  }}
                >
                  Cancel
                </MainButton>
              </div>

              <div className={styles['refer-pop-up__footer-button-list__item']}>
                <MainButton visualType="main" disabled>
                  Send emails and claim credits
                </MainButton>
              </div>
            </div>
          }
        >
          <p className={classNames(styles['refer-pop-up__text'], 'text text--body-2')}>
            Unlock rewards! Get $10 in credits for each friend you refer. Share the love and boost your benefits today!
          </p>

          <div className={styles['refer-pop-up__input-list']}>
            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #1" placeholder="Enter email address" size="large" name="refer_1" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #2" placeholder="Enter email address" size="large" name="refer_2" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #3" placeholder="Enter email address" size="large" name="refer_3" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #4" placeholder="Enter email address" size="large" name="refer_4" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #5" placeholder="Enter email address" size="large" name="refer_5" />
              </div>
            </div>
          </div>
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </div>
  );
};

export default AccountDashboard;
