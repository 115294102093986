import { FunctionComponent } from 'react';
import styles from './NotFoundView.module.scss';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface NotFoundViewProps {}

const NotFoundView: FunctionComponent<NotFoundViewProps> = () => {
  const navigate = useNavigate();

  return (
    <>
      <MetaTags
        title="Oops, looks like you're lost in space!"
        description="We couldn't find the page you were looking for. But don’t worry, we’re here to help you navigate."
      />

      <div className={styles['container']}>
        <div>
          <p className="text text--h1">Oops, looks like you're lost in space!</p>
          <p className="text text--p" style={{ marginTop: '24px' }}>
            We couldn't find the page you were looking for. But don’t worry, we’re here to help you navigate.
          </p>
          <MainButton
            style={{ marginTop: '24px' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Go back
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default NotFoundView;
