import { FunctionComponent } from 'react';
import MainTemplate from '../MainTemplate/MainTemplate';
import HeroSection from '../../../components/sections/HeroSection/HeroSection';
import HeroSectionSkeleton from '../../../components/sections/HeroSection/HeroSectionSkeleton';
import { useAppSelector } from '../../../redux/hooks';
import NotFoundView from '../../../views/NotFoundView/NotFoundView';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../../enums/routes';
import { GENERAL_CONTENT } from '../../../data/generalContent';
import Breadcrumbs from '../../../components/links/Breadcrumbs/Breadcrumbs';
import { UserRoleEnum } from '../../../enums/user';
import TextBillet from '../../../components/blocks/TextBillet/TextBillet';
import NavigationLink from '../../../components/links/NavigationLink/NavigationLink';
import SectionDivider from '../../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithElem from '../../../components/titles/TitleWithElem/TitleWithElem';
import ServiceCard from '../../../components/blocks/ServiceCard/ServiceCard';
import { getLastElementFromURL, truncateString, userFriendlyURLToString } from '../../../utils/stringFormatMethods';
import PreloadProductCard from '../../../components/blocks/PreloadProductCard/PreloadProductCard';
import { ReactComponent as ButtonArrow } from '../../../asset/images/button_arrow.svg';
import styles from './ServiceDetailTemplate.module.scss';
import Grid from '../../../components/blocks/Grid/Grid';
import MetaTags from '../../../components/seo/MetaTags/MetaTags';

interface ServiceDetailTemplateProps {
  title?: string;
  description?: string;
  relatedServices?: Array<any>;
  loading?: boolean;
  userRole: UserRoleEnum;
  viewAll?: string;
}

const ServiceDetailTemplate: FunctionComponent<ServiceDetailTemplateProps> = ({
  title,
  description,
  relatedServices,
  loading,
  userRole,
  viewAll,
}) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const navigate = useNavigate();

  if (loading) {
    return (
      <MainTemplate color="white">
        <HeroSectionSkeleton
          title="Meet 5 relevant founders in your city"
          text="We’ll match you with 5 other founders and business owners by shared industry, business type, and revenue stage for topical discussions over hearty meals."
          secondaryTitle={'Dinner'}
          sectionVariation="var-1-a"
          sectionBackgroundColor="Background-Neutral-Primary"
        />

        <SectionDivider color="Background-Neutral-Secondary" />
      </MainTemplate>
    );
  }

  if (!title) {
    return <NotFoundView />;
  }

  return (
    <MainTemplate color="white">
      <MetaTags title={title} description={description} />

      <HeroSection
        title={title ? title : ''}
        text={description ? description : ''}
        sectionVariation="var-1-b"
        sectionBackgroundColor="Background-Neutral-Primary"
        buttonGroup={
          !isUserLoggedIn
            ? [
                <MainButton
                  sizeType={'large'}
                  visualType="main"
                  onClick={() => {
                    navigate(`/${RoutesEnum.SIGN_UP}`);
                  }}
                >
                  {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
                </MainButton>,
              ]
            : null
        }
        breadcrumbs={
          <Breadcrumbs
            userRole={userRole}
            currentLinkText={userFriendlyURLToString(getLastElementFromURL(window.location.href))}
          />
        }
        customElement={
          !isUserLoggedIn ? (
            <TextBillet linkText="How hourly billing works" linkUrl={`/${userRole}/${RoutesEnum.PRICING}`}>
              <NavigationLink path={`/${RoutesEnum.SIGN_IN}`} sizeType="small">
                Sign in
              </NavigationLink>{' '}
              or{' '}
              <NavigationLink path={`/${RoutesEnum.SIGN_UP}`} sizeType="small">
                create an account
              </NavigationLink>{' '}
              to schedule a free consultation. We’ll introduce you to one of our UI/UX Designers to estimate your
              project.
            </TextBillet>
          ) : (
            <div className={styles['logged-block']}>
              <MainButton
                sizeType={'large'}
                visualType="main"
                style={{ width: '300px', maxWidth: '100%' }}
                className={styles['logged-block__button']}
                onClick={() => {
                  navigate(`/${userRole}/${RoutesEnum.SCHEDULE_MEETING}`);
                }}
              >
                Schedule a meeting
              </MainButton>

              <NavigationLink
                className={styles['logged-block__link']}
                path={`/${userRole}/${RoutesEnum.PRICING}`}
                sizeType="small"
              >
                How hourly billing works
              </NavigationLink>
            </div>
          )
        }
      />

      {/* <SectionDivider color="white" />

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle title="Case Studies" />}
        wrapperClass="type-1"
        style={{ overflow: 'hidden' }}
      >
        <Grid
          itemGroup={[
            <CaseStudyCard
              imageUrl={require('../../asset/images/case_study_image.jpg')}
              title="Candidly photographer booking platform"
              text="Consectetur turpis erat in sapien nullam. Congue hac netus ac ut purus dignissim quis imperdiet. Arcu tincidunt tellus volutpat turpis est. Pellentesque quam quam laoreet ultrices."
              logo={<CandidlyLogo />}
              price="$6,400"
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}/${RoutesEnum.CASE_STUDY}`}
            />,
            <CaseStudyCard
              imageUrl={require('../../asset/images/case_study_image.jpg')}
              title="Candidly photographer booking platform"
              text="Consectetur turpis erat in sapien nullam. Congue hac netus ac ut purus dignissim quis imperdiet. Arcu tincidunt tellus volutpat turpis est. Pellentesque quam quam laoreet ultrices."
              logo={<CandidlyLogo />}
              price="$6,400"
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}/${RoutesEnum.CASE_STUDY}`}
            />,
            <CaseStudyCard
              imageUrl={require('../../asset/images/case_study_image.jpg')}
              title="Candidly photographer booking platform"
              text="Consectetur turpis erat in sapien nullam. Congue hac netus ac ut purus dignissim quis imperdiet. Arcu tincidunt tellus volutpat turpis est. Pellentesque quam quam laoreet ultrices."
              logo={<CandidlyLogo />}
              price="$6,400"
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}/${RoutesEnum.CASE_STUDY}`}
            />,
          ]}
        />

        <ComingSoonMessage type="overlay" />
      </ChildrenWithHeaderSection> */}

      {relatedServices && relatedServices?.length > 0 && (
        <>
          <SectionDivider color="Background-Neutral-Secondary" />

          <ChildrenWithHeaderSection
            title={
              <TitleWithElem
                marginBottom="medium"
                element={
                  viewAll ? (
                    <NavigationLink path={viewAll} iconRight={<ButtonArrow />} sizeType="small" withBorder={false}>
                      View all
                    </NavigationLink>
                  ) : undefined
                }
              >
                <h4 className="text text--h4">Related products</h4>
              </TitleWithElem>
            }
            backgroundColor="Background-Neutral-Secondary"
          >
            {relatedServices && relatedServices?.length > 0 ? (
              <Grid
                itemGroup={relatedServices
                  ?.sort(() => 0.5 - Math.random())
                  .slice(0, 3)
                  .map((item, i) => {
                    return (
                      <ServiceCard
                        title={item.name}
                        text={truncateString(item.description, 100)}
                        price={null}
                        link={item.link}
                        key={item.id}
                        navigateState={item.navigateState}
                      />
                    );
                  })}
              />
            ) : (
              <Grid
                itemGroup={[1, 2, 3].map((item, i) => (
                  <PreloadProductCard key={i} />
                ))}
              />
            )}
          </ChildrenWithHeaderSection>
        </>
      )}
    </MainTemplate>
  );
};

export default ServiceDetailTemplate;
