import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import CompleteProfileHeader from './_parts/CompleteProfileHeader/CompleteProfileHeader';
import CompleteProfileFooter from './_parts/CompleteProfileFooter/CompleteProfileFooter';
import { ReactComponent as RocketIcon } from '../../../asset/images/icons/rocket.svg';
import classNames from 'classnames';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import PopUp from '../../../components/popups/PopUp/PopUp';
import styles from './CompleteProfileTemplate.module.scss';
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { localStorageGetItem } from '../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../enums/localStorageKeys';
import RoutesEnum from '../../../enums/routes';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../dto/api';
import Input from '../../../components/inputs/Input/Input';
import Label from '../../../components/texts/Label/Label';
import RadioList from '../../../components/inputs/RadioList/RadioList';
import CheckboxList from '../../../components/inputs/CheckboxList/CheckboxList';
import useMediaQuery from '../../../hooks/useMediaQuery';

interface CompleteProfileTemplateProps {
  formSchemaProp?: any;
  outputBySteps?: boolean;
  formDataInitial?: any;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideSidebar?: boolean;
  hideTitle?: boolean;
  showRfTipModalOnEnter?: boolean;
}

const CompleteProfileTemplate = forwardRef<unknown, CompleteProfileTemplateProps>(
  (
    {
      formSchemaProp,
      formDataInitial = {},
      outputBySteps = true,
      hideHeader = false,
      hideFooter = false,
      hideSidebar = false,
      hideTitle = false,
      showRfTipModalOnEnter = false,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [formSchema, setFormSchema] = useState<Array<any>>(formSchemaProp || []);
    const [formData, setFormData] = useState(formDataInitial);
    const [bonusRocketFuel, setBonusRocketFuel] = useState(0);
    const [totalRocketFuel, setTotalRocketFuel] = useState(0);

    const isTablet = useMediaQuery('(max-width: 1279px)');

    console.log('formData: ', formData);
    console.log('formSchema: ', formSchema);

    // STEPS
    const handleNextStep = () => {
      setCurrentStep(currentStep + 1);
    };

    const handlePrevStep = () => {
      setCurrentStep(currentStep - 1);
    };
    // STEPS END

    // POP UP
    const [skipPopUpActive, setSkipPopUpActive] = useState(false);
    const [rfPopUpActive, setRfPopUpActive] = useState(showRfTipModalOnEnter);

    const changeSkipPopUpActiveState = (state) => {
      setSkipPopUpActive(state);
    };

    const changeRfPopUpActiveState = (state) => {
      setRfPopUpActive(state);
    };
    // POP UP END

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const calculateTotalRocketFuel = (formSchema: any): number => {
      let totalRocketFuel = 0;

      if (formSchema) {
        formSchema.forEach((step: any) => {
          // Add Rocket Fuel from sections in the step
          step.sections.forEach((section: any) => {
            totalRocketFuel += section.rocketFuel || 0;
          });

          // Add Rocket Fuel from standalone questions in the step
          step.questions.forEach((question: any) => {
            totalRocketFuel += question.rocketFuel || 0;
          });
        });
      }

      return totalRocketFuel;
    };

    const accumulateRocketFuel = (formData: any, formSchema: any): number => {
      let totalRocketFuel = 0;

      formSchema.forEach((step: any) => {
        // Check sections
        step.sections.forEach((section: any) => {
          // Check if all questions in the section are answered
          const allQuestionsAnsweredInSection = section.questions.every((question: any) => {
            const answer = formData[question.questionID] && formData[question.questionID].value;
            return answer && (Array.isArray(answer) ? answer.length > 0 : answer !== '');
          });

          if (allQuestionsAnsweredInSection) {
            totalRocketFuel += section.rocketFuel; // Accumulate Rocket Fuel for complete sections
          }
        });

        // Check standalone questions (questions that are not part of sections)
        step.questions.forEach((question: any) => {
          const answer = formData[question.questionID] && formData[question.questionID].value;
          if (answer && (Array.isArray(answer) ? answer.length > 0 : answer !== '')) {
            totalRocketFuel += question.rocketFuel || 0; // Accumulate Rocket Fuel for answered questions
          }
        });
      });

      return totalRocketFuel;
    };

    const handleChange = (id: number, value: string, sectionId: number | null) => {
      const newFormData = {
        ...formData,
        [id]: { value: value, sectionId: sectionId },
      };

      setFormData(newFormData);
      setBonusRocketFuel(accumulateRocketFuel(newFormData, formSchema));
    };

    const handleSubmit = () => {
      const responses = Object.entries(formData).map(([key, value]) => {
        const { value: answer, sectionId } = value as any;

        return {
          questionId: parseInt(key),
          answer: Array.isArray(answer) ? answer.join(', ') : answer,
          sectionId: sectionId || null,
        };
      });

      const saveUserResponses = async () => {
        let success = true;

        if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID)) {
          try {
            const response = await api.Onboarding.saveUserResponses({
              userId: localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID) as string,
              responses: responses,
            });
            const responseData = response.data;

            if (responseData.success === true) {
              // navigate(`/${RoutesEnum.WELCOME}`);
            } else {
              success = false;
              console.error('Error:', responseData.errors);
              // navigate(`/${RoutesEnum.WELCOME}`);
            }
          } catch (error) {
            const errorObj = error as AxiosError<ApiResponseDTO>;
            const errorData = errorObj.response?.data;
            if (errorData?.errorMessage) {
              console.error(errorData.errorMessage);
            }
            console.error('Error: ', error);
            success = false;
            // navigate(`/${RoutesEnum.WELCOME}`);
          }
        } else {
          // navigate(`/${RoutesEnum.WELCOME}`);
        }

        return success;
      };

      return saveUserResponses();
    };

    const handleSubmitWrapper = async () => {
      const success = await handleSubmit();

      if (success === true) {
        navigate(`/${RoutesEnum.MANIFESTO}`);
      }
    };

    const renderStep = () => {
      // const step = formSchema.find((step) => step.stepOrder === currentStep);

      const step = formSchema.find((step, i) => i + 1 === currentStep);

      console.log('HERE', step);

      if (!step) return null;

      return [
        step.questions && step.questions.map((question) => renderQuestion(question, null)),
        step.sections && step.sections.map((section) => renderSection(section)),
      ];
    };

    const renderAllSteps = () => {
      return [
        formSchema.map((item) => item.type === 'Section' && renderSection(item)),
        formSchema.map((item) => item.type === 'Question' && renderQuestion(item.question, null)),
      ];

      // return [
      //   formSchema.map((item) => item.type === 'Section' && item.questions.map((section) => renderSection(section))),
      //   formSchema.map(
      //     (step, stepNum) => step.questions && step.questions.map((question) => renderQuestion(question, null))
      //   ),
      // ];
    };

    enum SectionNames {
      'Your Company' = 'section__form-layout--your-company',
      'Your Former Company' = 'section__form-layout--your-former-company',
    }

    const renderSection = (section) => {
      const sectionID = section ? section.sectionID || section.id || null : null;
      const sectionTitle = section.sectionName || section.name;

      return (
        <div className={styles['section']} key={sectionID}>
          {/* <p className={classNames(styles['section__title'], 'text text--caps')}>{sectionTitle}</p> */}

          <div className={classNames(styles['tile'], 'tile')}>
            <p className={classNames(styles['section__title-2'], 'text text--body-1 text--bold')}>
              <span className={styles['section__title-2__text']}>{sectionTitle}</span>
              {renderLabelRF(section)}
            </p>

            <div className={classNames(styles['section__form-layout'], styles[SectionNames[sectionTitle]])}>
              {section.questions.map((question) => renderQuestion(question, section))}
            </div>
          </div>
        </div>
      );
    };

    const renderLabelRF = (data) => {
      if (data) {
        if (data.rocketFuel) {
          if (data.questions) {
            let isAllAnswered = true;
            data.questions.forEach((element) => {
              if (element.answer === null || element.answer === undefined) {
              } else {
                isAllAnswered = false;
              }
            });

            if (isAllAnswered === true) {
              return <Label text={`+$${data.rocketFuel} RF`} visualStyle="main-rounded" />;
            }
          } else {
            if (data.answer === null || data.answer === undefined) {
              return <Label text={`+$${data.rocketFuel} RF`} visualStyle="main-rounded" />;
            }
          }
        }
      }
    };

    const renderQuestion = (question, section) => {
      const sectionID = section ? section.sectionID || section.id || null : null;

      switch (question.questionType) {
        case 'Text':
          const inputComponent = (
            <div className={styles['section__form-layout__input']}>
              <Input
                label={
                  <div className={styles['special-label']}>
                    <div className={styles['special-label__text']}>{question.displayName}</div>

                    {renderLabelRF(question)}
                  </div>
                }
                placeholder={question.placeholder}
                size="large"
                name={question.PropertyName}
                value={formData[question.questionID] ? formData[question.questionID].value : ''}
                onChange={(e) => handleChange(question.questionID, e.target.value, section ? sectionID : null)}
              />

              {question.description && (
                <p className={classNames(styles['tip-for-input'], 'text text--caption')}>{question.description}</p>
              )}
            </div>
          );

          return !section ? (
            <div className={styles['section']} key={question.questionID}>
              <div className={classNames(styles['tile'], 'tile')}>{inputComponent}</div>
            </div>
          ) : (
            inputComponent
          );
        case 'Choice':
          const choiceComponent = (
            <div className={styles['section__form-layout__input']}>
              {sectionID === null ? (
                <p className={classNames(styles['section__title-2'], 'text text--body-1 text--bold')}>
                  <span className={styles['section__title-2__text']}>{question.displayName}</span>
                  {renderLabelRF(question)}
                </p>
              ) : (
                <p className={classNames(styles['section__title-2'], 'text text--body-2 text--bold')}>
                  <span className={styles['section__title-2__text']}>{question.displayName}</span>
                  {renderLabelRF(question)}
                </p>
              )}

              {question.description ? (
                <p className={classNames(styles['section__text'], 'text text--caption')}>{question.description}</p>
              ) : (
                // <p className={classNames(styles['section__text'], 'text text--caption')}>???</p>
                <></>
              )}

              <div className={styles['section__form-layout__input__object']}>{renderChoice(question, section)}</div>
            </div>
          );

          return !section ? (
            <div className={styles['section']} key={question.questionID}>
              <div className={classNames(styles['tile'], 'tile')}>{choiceComponent}</div>
            </div>
          ) : (
            choiceComponent
          );
        default:
          return null;
      }
    };

    const renderChoice = (question, section) => {
      const sectionID = section ? section.sectionID || section.id || null : null;

      switch (question.choiceType) {
        case 'Radio':
          return (
            <RadioList
              name={question.questionID}
              sizeSmall={true}
              data={question.options.map((option) => {
                return { value: option, label: option };
              })}
              checkedValue={formData[question.questionID]?.value}
              className={classNames(styles['section__radio-list'], question.options.length >= 12)}
              onChange={(e) => {
                handleChange(question.questionID, e.value, section ? sectionID : null);
              }}
            />
          );
        case 'Checkbox':
          const checkboxListValues = question.options.map((option) => {
            return option;
          });

          const checkedValues = checkboxListValues.map((option) => {
            if (formData[question.questionID]) {
              if (formData[question.questionID].value.length) {
                return formData[question.questionID].value.some((option_2) => option.trim() === option_2.trim());
              }
            }
          });

          const initialData =
            formData[question.questionID] &&
            formData[question.questionID].value.map((option) => {
              return { name: question.questionID, value: option.trim(), checked: true };
            });

          return (
            <CheckboxList
              sizeSmall={true}
              data={question.options.map((option) => {
                return { value: option.trim(), label: option.trim() };
              })}
              dataToSetCheckedState={checkedValues}
              checkboxInitialData={initialData}
              className={classNames(styles['section__checkbox-list'])}
              name={question.questionID}
              onChange={(e) => {
                handleChange(
                  question.questionID,
                  e.map((checkboxItem, i) => checkboxItem.value),
                  section ? sectionID : null
                );
              }}
            />
          );
        default:
          return null;
      }
    };

    // GET STEPS
    // useEffect(() => {
    //   const getOnboardingSteps = async () => {
    //     if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID)) {
    //       try {
    //         const response = await api.Onboarding.getSteps(localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID) as string);
    //         const responseData = response.data;

    //         if (responseData.success === true && responseData.data) {
    //           // setFormSchema(responseData.data);
    //           console.log('ORIGINAL SCHEMA: ', responseData.data);
    //         } else {
    //           console.error('Error:', responseData.errors);
    //           // navigate(`/${RoutesEnum.WELCOME}`);
    //         }
    //       } catch (error) {
    //         const errorObj = error as AxiosError<ApiResponseDTO>;
    //         const errorData = errorObj.response?.data;
    //         if (errorData?.errorMessage) {
    //           console.error(errorData.errorMessage);
    //         }
    //         console.error('Error: ', error);
    //         // navigate(`/${RoutesEnum.WELCOME}`);
    //       }
    //     } else {
    //       // navigate(`/${RoutesEnum.WELCOME}`);
    //     }
    //   };

    //   getOnboardingSteps();
    // }, []);

    useEffect(() => {
      function getInitialFormData(schema) {
        const data = {};

        if (schema) {
          schema.forEach((section) => {
            if (section.type === 'Section' && section.questions) {
              section.questions.forEach((question) => {
                if (question.answer) {
                  const { questionID, propertyName } = question;
                  data[propertyName || questionID] = {
                    value: question.answer,
                    sectionId: section.id, // Assign sectionId to the value object
                  };

                  // Handle checkbox type with an array value
                  if (question.choiceType === 'Checkbox') {
                    data[propertyName || questionID].value = question.answer.split(',').map((choice) => choice.trim()); // Split and trim answers
                  }
                }
              });
            } else if (section.type === 'Question' && section.question) {
              const question = section.question;
              if (question.answer) {
                const { questionID, propertyName } = question;

                data[questionID || propertyName] = {
                  value: question.answer,
                  sectionId: null, // Assign sectionId to the value object
                };

                // Handle checkbox type with an array value
                if (question.choiceType === 'Checkbox') {
                  data[questionID || propertyName].value = question.answer.split(',').map((choice) => choice.trim()); // Split and trim answers
                }
              }
            }
          });
        }

        return data;
      }

      let initialFormData = getInitialFormData(formSchemaProp);

      console.log('INITIAL: ', initialFormData);

      if (initialFormData) {
        setFormData(initialFormData);
      }

      setFormSchema(formSchemaProp);

      setTotalRocketFuel(calculateTotalRocketFuel(formSchemaProp));
    }, [formSchemaProp]);

    if (formSchema) {
    } else {
      return <></>;
    }
    // GET STEPS END

    return (
      <div className={classNames(styles['template'], outputBySteps === false && styles['template--edit-role'])}>
        {hideHeader === false && (
          <CompleteProfileHeader rocketFuel={bonusRocketFuel} currentStep={currentStep} formSchema={formSchema} />
        )}

        <main className={styles['main']}>
          <div className="wrapper type-1">
            <div className={styles['content']}>
              <div className={styles['part-list']}>
                <div className={styles['part-list__main']}>
                  {hideTitle === false && (
                    <>
                      <div className={styles['sec-title']}>
                        <p className="text text--caps-small">Complete your profile</p>
                      </div>

                      <div className={styles['title']}>
                        <h3 className="text text--h3">{formSchema?.[currentStep - 1]?.stepTitle}</h3>
                      </div>

                      <p className={classNames(styles['sec-title-2'], 'text text--body-1')}>
                        Complete your profile so that we can relevantly serve your interests. Earn free Rocket Fuel for
                        each field filled.
                      </p>

                      {hideSidebar === false && isTablet === true && (
                        <aside className={styles['part-list__side']}>
                          <div className={classNames(styles['info'], 'tile')}>
                            <h5 className={classNames(styles['info__title'], 'text text--h5')}>
                              Why is this important?
                            </h5>

                            <p className={classNames(styles['info__text'], 'text text--body-2')}>
                              Our goal is to serve your needs as relevantly we we can.
                              <br />
                              <br />
                              Knowing your business attributes enables us to make relevant introductions for you, to
                              invite you to relevant events, and to deliver you products and services that are relevant
                              to your business type, industry, growth stage, and interests.
                            </p>

                            {/* <div className={styles['info__button']}>
                              <MainButton sizeType="medium" visualType="white">
                                Learn more
                              </MainButton>
                            </div> */}
                          </div>
                        </aside>
                      )}
                    </>
                  )}

                  {/* <button onClick={handleSubmit}>TEST</button> */}

                  <div className={styles['part-list__main__outlet']}>
                    {outputBySteps === true ? renderStep() : renderAllSteps()}
                  </div>

                  {hideSidebar === false && isTablet === true && (
                    <aside className={styles['part-list__side']}>
                      <div className={classNames(styles['fuel-info'], 'tile', 'tile--color-main')}>
                        <p className={classNames(styles['fuel-info__text'], 'text text--caps-small')}>
                          Rocket Fuel earned
                        </p>

                        <p className={classNames(styles['fuel-info__number'], 'text text--h2')}>${bonusRocketFuel}</p>

                        <RocketIcon className={styles['fuel-info__icon']} />
                      </div>

                      <MainButton
                        className={styles['adaptive-skip-button']}
                        sizeType="large"
                        visualType="white"
                        onClick={() => changeSkipPopUpActiveState(true)}
                      >
                        Skip
                      </MainButton>
                    </aside>
                  )}
                </div>

                {hideSidebar === false && isTablet === false && (
                  <aside className={styles['part-list__side']}>
                    <div className={classNames(styles['info'], 'tile')}>
                      <h5 className={classNames(styles['info__title'], 'text text--h5')}>Why is this important?</h5>

                      <p className={classNames(styles['info__text'], 'text text--body-2')}>
                        Our goal is to serve your needs as relevantly we we can.
                        <br />
                        <br />
                        Knowing your business attributes enables us to make relevant introductions for you, to invite
                        you to relevant events, and to deliver you products and services that are relevant to your
                        business type, industry, growth stage, and interests.
                      </p>

                      {/* <div className={styles['info__button']}>
                        <MainButton sizeType="medium" visualType="white">
                          Learn more
                        </MainButton>
                      </div> */}
                    </div>

                    <div className={classNames(styles['fuel-info'], 'tile', 'tile--color-main')}>
                      <p className={classNames(styles['fuel-info__text'], 'text text--caps-small')}>
                        Rocket Fuel earned
                      </p>

                      <p className={classNames(styles['fuel-info__number'], 'text text--h2')}>${bonusRocketFuel}</p>

                      <RocketIcon className={styles['fuel-info__icon']} />
                    </div>
                  </aside>
                )}
              </div>
            </div>
          </div>
        </main>

        {createPortal(
          <PopUp
            className="skip-pop-up"
            title="Are you sure you want to skip?"
            active={skipPopUpActive}
            onPopUpStateChange={changeSkipPopUpActiveState}
            footer={
              <div className={styles['skip-pop-up__footer-button-list']}>
                <div className={styles['skip-pop-up__footer-button-list__item']}>
                  <MainButton
                    onClick={() => {
                      changeSkipPopUpActiveState(false);
                    }}
                  >
                    Complete Profile
                  </MainButton>
                </div>

                <div className={styles['skip-pop-up__footer-button-list__item']}>
                  <MainButton visualType="white" onClick={handleSubmitWrapper}>
                    Skip, I'll do it later
                  </MainButton>
                </div>
              </div>
            }
          >
            <p className={classNames(styles['skip-pop-up__text-1'], 'text text--body-2')}>
              By completing each step, you can earn:
            </p>

            <div className={styles['skip-pop-up__fuel-tile']}>
              <div className={styles['skip-pop-up__fuel-tile__icon']}>
                <RocketIcon style={{ fill: 'var(--color-main)', width: '24px' }} />
              </div>

              <h4 className={classNames(styles['skip-pop-up__fuel-tile__text'], 'text text--h4')}>
                {`$${totalRocketFuel} in Rocket Fuel Credits`}
              </h4>
            </div>

            <p className={classNames(styles['skip-pop-up__text-2'], 'text text--body-2')}>
              These credits can be used to pay for products and services. These forms will help us ensure that{' '}
              <b>the services you choose are tailored to your specific needs and preferences</b>.
            </p>

            <p className={classNames(styles['skip-pop-up__text-3'], 'text text--body-2')}>
              However, if you're in a rush, don't worry! You can always fill it out later in your profile page.
            </p>
          </PopUp>,
          document.getElementById('modal') as HTMLElement
        )}

        {createPortal(
          <PopUp
            className={styles['rf-pop-up']}
            popUpWidth="560"
            active={rfPopUpActive}
            onPopUpStateChange={changeRfPopUpActiveState}
            footer={
              <MainButton className={styles['rf-pop-up__button']} onClick={() => changeRfPopUpActiveState(false)}>
                Got it
              </MainButton>
            }
          >
            <div className={styles['rf-pop-up__icon-container']}>
              <RocketIcon style={{ fill: 'var(--brand-orange-600)', width: '32px' }} />
            </div>

            <p className={classNames(styles['rf-pop-up__title'], 'text text--h4')}>Earn free Rocket Fuel</p>

            <p className={classNames(styles['rf-pop-up__text'], 'text text--body-2')}>
              Now that you understand how Rocket Fuel works, fill out your profile to earn as much as ${totalRocketFuel}{' '}
              in free Rocket Fuel credits. You can use these credits to buy any product or service on Astronomic.
            </p>
          </PopUp>,
          document.getElementById('modal') as HTMLElement
        )}

        {hideFooter === false && (
          <CompleteProfileFooter
            onSkipPopUpStateChange={changeSkipPopUpActiveState}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
            currentStep={currentStep}
            hideSkipButton={isTablet}
            formSchema={formSchema}
            onSubmit={handleSubmitWrapper}
          />
        )}
      </div>
    );
  }
);

export default CompleteProfileTemplate;
