import { FunctionComponent, useEffect, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as ConnectIll } from '../../asset/images/connect_illustration.svg';
import MainButton from '../../components/buttons/MainButton/MainButton';
import CardsWithHeaderSection from '../../components/sections/CardsWithHeaderSection/CardsWithHeaderSection';
import Grid from '../../components/blocks/Grid/Grid';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import { ReactComponent as ThumbIcon } from '../../asset/images/icons/thumbs_up.svg';
import { ReactComponent as StairUpIcon } from '../../asset/images/icons/stair_up.svg';
import { ReactComponent as CaseIcon } from '../../asset/images/icons/case.svg';
import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import connectPageContent from '../../data/connect';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import { getRoleIdByUserRole } from '../../utils/role';
import api from '../../api';
import { getCollectionOfStringDividedByWord, stringToUserFriendlyURL } from '../../utils/stringFormatMethods';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';
import StandardAndRocketFuelPricing from '../../components/blocks/StandardAndRocketFuelPricing/StandardAndRocketFuelPricing';
import PreloadProductCard from '../../components/blocks/PreloadProductCard/PreloadProductCard';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface ConnectViewProps extends IWithUserRoleProps {}

const ConnectView: FunctionComponent<ConnectViewProps> = ({ user_role, router }) => {
  const [connectPrograms, setConnectPrograms] = useState<any[] | undefined>([]);

  const getConnectPrograms = async () => {
    try {
      const response = await api.Content.getConnectProgramsByRole(getRoleIdByUserRole(user_role));
      const responseData = response.data;

      if (responseData.success && responseData.data) {
        setConnectPrograms(responseData.data);
      } else {
        console.error('Error:', responseData.errors);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    getConnectPrograms();
  }, [user_role]);

  return (
    <MainTemplateForMainPages>
      <MetaTags title="Connect" description={connectPageContent[user_role]} />

      <HeroSection
        secondaryTitle="Connect"
        title="Meet relevant founders and investors"
        text={connectPageContent[user_role]}
        sectionVariation="var-1"
        illustration={<ConnectIll style={{ maxHeight: '400px' }} />}
      />

      <SectionDivider color="Background-Neutral-Primary" />

      <CardsWithHeaderSection
        title="Connect"
        text="Join other founders and investors for business leadership discussion, Q&A, and resource sharing. This community is moderated by our team."
        sectionBackgroundColor="Background-Neutral-Primary"
        sectionVariation="var-1"
        button={<JoinForFreeButton />}
      >
        {connectPrograms && connectPrograms.length > 0 ? (
          <Grid
            itemGroup={connectPrograms?.map((item, i) => {
              const standardValue = getCollectionOfStringDividedByWord('/', item.standardPrice);
              const rocketFuelValue = getCollectionOfStringDividedByWord('/', item.rocketFuelPrice);

              return (
                <ServiceCard
                  key={item.id}
                  title={item.programName}
                  text={item.description}
                  price={item.standardPrice === 'Free' ? 'Free' : null}
                  link={`/${user_role}/${RoutesEnum.CONNECT}/${stringToUserFriendlyURL(item.programName)}`}
                  navigateState={{ programId: item.id }}
                  customPreFooter={
                    item.standardPrice === 'Free' ? (
                      <></>
                    ) : (
                      <StandardAndRocketFuelPricing
                        content={{
                          standard: {
                            value: standardValue.firstPart,
                            valueAfter: standardValue.word + standardValue.secondPart,
                          },
                          rocketFuel: {
                            value: rocketFuelValue.firstPart,
                            valueAfter: rocketFuelValue.word + rocketFuelValue.secondPart,
                          },
                        }}
                      />
                    )
                  }
                />
              );
            })}
            itemsInRow={3}
          />
        ) : (
          <Grid
            itemGroup={Array.from({ length: 3 }).map((_, i) => (
              <PreloadProductCard key={i} />
            ))}
          />
        )}
      </CardsWithHeaderSection>

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle secTitle="Collaborate to succeed" title="Better together" />}
        backgroundColor="Background-Neutral-Primary"
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Better together"
              text="It's lonely at the top, but not here. Share your challenges, goals, and questions with other founders, learn from their experiences, and develop rewarding friendships in the process."
              icon={<ThumbIcon style={{ maxWidth: '29px' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Accountability"
              text="It’s one thing to set your own goals, but it’s another thing to verbalize them to your peers. Set growth goals and let fellow founders hold you accountable to achieve them."
              icon={<StairUpIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Skills"
              text="Gain valuable education and level-up your business skills, thanks to the insights shared by fellow founders who’ve been there/done that."
              icon={<CaseIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Growth"
              text="Track your success over time, build valuable connections, and take your business to the next level thanks to the power of Astronomic Network."
              icon={<RocketIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>
    </MainTemplateForMainPages>
  );
};

export default with_user_role(ConnectView);
